const Service = ({ services, bookingData, setBookingData }) => {
  const handleChange = (service) => {
    setBookingData((prevState) => ({
      ...prevState,
      bookingDetails: {
        ...prevState.bookingDetails,
        type_of_service: service,
      },
    }));
  };

  return (
    <fieldset className="inputs-wrapper step-wrapper">
      {services?.map((el, index) => (
        <div
          key={index}
          className={`service-wrapper ${
            el.service_name === bookingData.bookingDetails.type_of_service ? 'active' : 'inactive'
          }`}
          onClick={() => handleChange(el.service_name)}
        >
          <span>{el.service_name}</span>
          <p>{el.service_description}</p>
        </div>
      ))}
    </fieldset>
  );
};

export default Service;
