import { NavLink } from 'react-router-dom';

import './thanks.scss';

const ThankYou = ({ city = window.location.pathname.split('/')[1] }) => (
  <div className="thanks-wrapper">
    <div className="container">
      <div className="section-title">
        <h2>Thank You!</h2>
      </div>

      <p className="subtitle">We’ll respond to your request shortly!</p>
      <p>Looking forward to serving you!</p>

      <NavLink to={`//local-appliance-repair.pro/${city}`} className="btn green">
        Back To Home Page
      </NavLink>
    </div>
  </div>
);

export default ThankYou;
