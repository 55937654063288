import { Layout, ThankYou } from '../components';

const ThankYouPage = ({ settings }) => {
  return (
    <Layout pageId="thank-you-page" phone={settings.phone.description}>
      <section>
        <ThankYou />
      </section>
    </Layout>
  );
};

export default ThankYouPage;
